import React from 'react';
import { makeStyles } from '@material-ui/styles';
import dayjs from 'dayjs';

import themeColors from '../../assets/colors';

import '../../assets/css/print.css';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    container: {
        display: 'block',
        pageBreakAfter: 'always'
    },
    logo: {
        alignSelf: 'center',
        maxHeight: 50
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 8
    },
    MContainer: {
        display: 'block',
        pageBreakInside: 'avoid',
        marginBottom: 5
    },
    NMContainer: {
        display: 'block',
        pageBreakInside: 'auto',
        marginBottom: 5
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: '#F2F2F2',
        padding: 4,
        fontSize: 8
    },
    totalsContainer: {
        backgroundColor: '#F2F2F2'
    },
    tableContainer: {
        overflowX: 'auto',
        width: '100%'
    },
    summaryTable: {
        width: '100%',
        borderCollapse: 'collapse',
        overflow: 'hidden'
    },
    tableRow: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#e0e0e0'
    },
    summaryHeaderText: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        padding: 4,
        fontSize: 8,
        color: '#FFF',
        fontWeight: 'bold'
    },
    summaryText: {
        color: '#FFF',
        padding: 4,
        fontSize: 8,
        textAlign: 'center',
        whiteSpace: 'nowrap'
    },
    tableHeaderText: {
        textAlign: 'center',
        whiteSpace: 'nowrap',
        padding: 4,
        fontSize: 8,
        color: '#000',
        fontWeight: 'bold'
    },
    tableText: {
        color: '#000',
        padding: 4,
        fontSize: 8,
        textAlign: 'center',
        whiteSpace: 'nowrap'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        color: themeColors.app.main,
        marginTop: 10,
        marginBottom: 10
    }
}));

const EmployeePayToPrint = ({ data }) => {

    const classes = useStyles();

    const getTotalsM = header => {
        let hours = '0', regular_hours = '0', regular_hours_pay = '0', overtime_hours = '0', overtime_hours_pay = '0', total_qty = '0', base_pay = '0', real_pay = '0', bonus = '0';

        header.subheadersM?.map(subheader => {
            hours = (Number(hours) + Number(subheader.hours)).toFixed(2);
            regular_hours = (Number(regular_hours) + Number(subheader.regular_hours)).toFixed(2);
            regular_hours_pay = (Number(regular_hours_pay) + Number(subheader.regular_hours_pay)).toFixed(2);
            overtime_hours = (Number(overtime_hours) + Number(subheader.overtime_hours)).toFixed(2);
            overtime_hours_pay = (Number(overtime_hours_pay) + Number(subheader.overtime_hours_pay)).toFixed(2);
            base_pay = (Number(base_pay) + Number(subheader.base_pay)).toFixed(2);
            bonus = (Number(bonus) + Number(subheader.bonus)).toFixed(2);
            real_pay = (Number(real_pay) + Number(subheader.real_pay)).toFixed(2);

            subheader.detail?.map(item => {
                total_qty = Number(total_qty) + Number(item.total_qty);

                return false;
            });

            return false;
        });

        return [hours, regular_hours, `$ ${regular_hours_pay}`, overtime_hours, `$ ${overtime_hours_pay}`, total_qty, `$${base_pay}`, `$${bonus}`, `$${real_pay}`];
    }

    const getTotalsNM = subheadersNM => {
        let hours = 0, reg = 0, regPay = 0, ot1 = 0, ot1Pay = 0, ot2 = 0, ot2Pay = 0, total = 0;

        subheadersNM?.map(subheader => {
            subheader?.detail?.map(item => {
                hours = (Number(hours) + Number(item.hours)).toFixed(2);
                reg = (Number(reg) + Number(item.regular_hours)).toFixed(2);
                regPay = (Number(regPay) + Number(item.regular_hours_pay)).toFixed(2);
                ot1 = (Number(ot1) + Number(item.overtime_week)).toFixed(2);
                ot1Pay = (Number(ot1Pay) + Number(item.overtime_week_pay)).toFixed(2);
                ot2 = (Number(ot2) + Number(item.overtime_holiday)).toFixed(2);
                ot2Pay = (Number(ot2Pay) + Number(item.overtime_holiday_pay)).toFixed(2);
                total = (Number(total) + Number(item.total)).toFixed(2);

                return null;
            });

            return null;
        });

        return [hours, reg, `$ ${regPay}`, ot1, `$ ${ot1Pay}`, ot2, `$ ${ot2Pay}`, `$ ${total}`];
    }

    const getProduct = txt => {
        const limit = 32;
        const cont = txt.length;

        return (cont > limit) ? txt.substr(0, limit - 3).concat('...') : txt.substr(0, limit);
    }

    return (
        <div className={classes.root}>
            <div>
                {
                    data.map(header =>
                        <div key={header.idemployee_pay_header} className={classes.container}>
                            <div className={classes.header}>
                                <div className={classes.description}>
                                    <span><b>{`Date: `}</b>{dayjs().format('MM/DD/YYYY HH:mm')}</span>
                                    <span><b>{`Sede: `}</b>{header.sede.name}</span>
                                    <span><b>{`Year-Week: `}</b>{header.week}</span>
                                    <span><b>{`Employee: `}</b>{header.employee.name}</span>
                                    <span><b>{`Base rate: `}</b>{`$ ${header.base_rate}`}</span>
                                </div>

                                <div className={classes.description}>
                                    <span><b>{`OT base rate: `}</b>{`$ ${header.overtime_base_rate}`}</span>
                                    <span><b>{`Total hours: `}</b>{header.hours}</span>
                                    <span><b>{`Total paid: `}</b>{`$ ${header.total}`}</span>
                                    <span><b>{`Regular overtime: `}</b>{`$ ${header.regular_overtime ? '✓' : '✗'}`}</span>
                                    <span><b>{`Bonus payment: `}</b>{`$ ${header.bonus_payment ? '✓' : '✗'}`}</span>
                                </div>

                                <img className={classes.logo} src={Logo} alt='logo cfg' />
                            </div>

                            <div className={classes.tableContainer}>
                                <table className={classes.summaryTable} style={{ backgroundColor: themeColors.app.main }}>
                                    <thead>
                                        <tr className={classes.tableRow}>
                                            <th className={classes.summaryHeaderText}>{'Total hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Regular hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Regular hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'OT hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'OT hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'Total paid'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={classes.summaryText}>{header.hours}</td>
                                            <td className={classes.summaryText}>{header.regular_hours}</td>
                                            <td className={classes.summaryText}>{`$ ${header.regular_hours_pay}`}</td>
                                            <td className={classes.summaryText}>{header.overtime_hours}</td>
                                            <td className={classes.summaryText}>{`$ ${header.overtime_hours_pay}`}</td>
                                            <td className={classes.summaryText}>{`$ ${header.total}`}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.summaryText}>{'-'}</td>
                                            <td className={classes.summaryText}>{'-'}</td>
                                            <td className={classes.summaryText}>{'-'}</td>
                                            <td className={classes.summaryText}>{'-'}</td>
                                            <td className={classes.summaryText}>{`$ ${(header.overtime_hours * header.overtime_base_rate).toFixed(2)}`}</td>
                                            <td className={classes.summaryText}>{`$ ${header.paylocity_total}`}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={classes.title}>{'Payment (M)'}</div>
                            {
                                header.subheadersM.map(subheader =>
                                    <div key={subheader.idemployee_pay_subheader} className={classes.MContainer}>
                                        <div className={classes.tableHeader}>
                                            <span><b>{`Date: `}</b>{dayjs(subheader.date).format('MM/DD/YYYY')}</span>
                                            <span><b>{'Regular hours: '}</b>{subheader.regular_hours}</span>
                                            <span><b>{'OT hours: '}</b>{subheader.overtime_hours}</span>
                                        </div>

                                        <div className={classes.tableContainer}>
                                            <table className={classes.summaryTable} style={{ backgroundColor: '#FFF' }}>
                                                <thead>
                                                    <tr className={classes.tableRow}>
                                                        <th className={classes.tableHeaderText}>{'Position'}</th>
                                                        <th className={classes.tableHeaderText}>{'Product code'}</th>
                                                        <th className={classes.tableHeaderText} style={{ backgroundColor: '#DDF5FD' }}>{'Hours pay'}</th>
                                                        {
                                                            Boolean(Number(header.overtime_base_rate)) &&
                                                            <th className={classes.tableHeaderText} style={{ backgroundColor: '#ECCEF5' }}>{'OT'}</th>
                                                        }
                                                        <th className={classes.tableHeaderText}>{'Product rate'}</th>
                                                        <th className={classes.tableHeaderText}>{'Total qty'}</th>
                                                        <th className={classes.tableHeaderText} style={{ backgroundColor: '#DDF5FD' }}>{'Base rate'}</th>
                                                        <th className={classes.tableHeaderText} style={{ backgroundColor: '#EDFBDB' }}>{'Real pay'}</th>
                                                        <th className={classes.tableHeaderText}>{'Bonus'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        subheader.detail.map(item =>
                                                            <tr key={item.idemployee_pay} className={classes.tableRow}>
                                                                <td className={classes.tableText}>{item.position}</td>
                                                                <td className={classes.tableText}>{getProduct(`${item.product.code} - ${item.product.description}`)}</td>
                                                                <td className={classes.tableText} style={{ backgroundColor: '#DDF5FD' }}>{'-'}</td>
                                                                {
                                                                    Boolean(Number(header.overtime_base_rate)) &&
                                                                    <td className={classes.tableText} style={{ backgroundColor: '#ECCEF5' }}>{'-'}</td>
                                                                }
                                                                <td className={classes.tableText}>{`$ ${item.product_rate}`}</td>
                                                                <td className={classes.tableText}>{item.total_qty}</td>
                                                                <td className={classes.tableText} style={{ backgroundColor: '#DDF5FD' }}>{`$ ${item.base_pay}`}</td>
                                                                <td className={classes.tableText} style={{ backgroundColor: '#EDFBDB' }}>{'-'}</td>
                                                                <td className={classes.tableText}>{'-'}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    <tr className={classes.totalsContainer}>
                                                        <td className={classes.tableText}><b>{'Totals:'}</b></td>
                                                        <td className={classes.tableText}>{ }</td>
                                                        <td className={classes.tableText} style={{ backgroundColor: '#DDF5FD' }}><b>{`$ ${subheader.regular_hours_pay}`}</b></td>
                                                        {
                                                            Boolean(Number(header.overtime_base_rate)) &&
                                                            <td className={classes.tableText} style={{ backgroundColor: '#ECCEF5' }}><b>{`$ ${subheader.overtime_hours_pay}`}</b></td>
                                                        }
                                                        <td className={classes.tableText}>{ }</td>
                                                        <td className={classes.tableText}>{ }</td>
                                                        <td className={classes.tableText} style={{ backgroundColor: '#DDF5FD' }}><b>{`$ ${subheader.base_pay}`}</b></td>
                                                        <td className={classes.tableText} style={{ backgroundColor: '#EDFBDB' }}><b>{`$ ${subheader.real_pay}`}</b></td>
                                                        <td className={classes.tableText}><b>{`$ ${subheader.bonus}`}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }
                            <div className={classes.tableContainer}>
                                <table className={classes.summaryTable} style={{ backgroundColor: '#6E6E6E' }}>
                                    <thead>
                                        <tr className={classes.tableRow}>
                                            <th className={classes.summaryHeaderText}>{'Total hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Regular hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Regular hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'OT hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'OT hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'Total piece qty'}</th>
                                            <th className={classes.summaryHeaderText}>{'Piece pay'}</th>
                                            <th className={classes.summaryHeaderText}>{'Bonus'}</th>
                                            <th className={classes.summaryHeaderText}>{'Total paid'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {
                                                getTotalsM(header).map((item, i) => <td key={i.toString()} className={classes.summaryText}>{item}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={classes.title}>{'Payment (NM)'}</div>
                            {
                                header.subheadersNM.map(subheader => {
                                    return (
                                        <div key={subheader.idemployee_pay_subheader_nm} className={classes.NMContainer}>
                                            <div className={classes.tableHeader}>
                                                <span><b>{'Total hours: '}</b>{subheader.hours}</span>
                                                <span><b>{'Total paid: '}</b>{`$ ${subheader.total}`}</span>
                                            </div>
                                            <div className={classes.tableContainer}>
                                                <table className={classes.summaryTable} style={{ backgroundColor: '#FFF' }}>
                                                    <thead>
                                                        <tr className={classes.tableRow}>
                                                            <th className={classes.tableHeaderText}>{'Date'}</th>
                                                            <th className={classes.tableHeaderText}>{'Total hours'}</th>
                                                            <th className={classes.tableHeaderText}>{'Regular hours'}</th>
                                                            <th className={classes.tableHeaderText}>{'Regular hours $'}</th>
                                                            <th className={classes.tableHeaderText}>{'OT hours'}</th>
                                                            <th className={classes.tableHeaderText}>{'OT hours $'}</th>
                                                            <th className={classes.tableHeaderText}>{'Holiday hours'}</th>
                                                            <th className={classes.tableHeaderText}>{'Holiday hours $'}</th>
                                                            <th className={classes.tableHeaderText}>{'Total paid'}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            subheader.detail.map(item =>
                                                                <tr key={item.idemployee_pay_nm} className={classes.tableRow}>
                                                                    <td className={classes.tableText}>{dayjs(item.date).format('MM/DD/YYYY')}</td>
                                                                    <td className={classes.tableText}>{item.hours}</td>
                                                                    <td className={classes.tableText}>{item.regular_hours}</td>
                                                                    <td className={classes.tableText}>{`$ ${item.regular_hours_pay}`}</td>
                                                                    <td className={classes.tableText}>{item.overtime_week}</td>
                                                                    <td className={classes.tableText}>{`$ ${item.overtime_week_pay}`}</td>
                                                                    <td className={classes.tableText}>{item.overtime_holiday}</td>
                                                                    <td className={classes.tableText}>{`$ ${item.overtime_holiday_pay}`}</td>
                                                                    <td className={classes.tableText}>{`$ ${item.total}`}</td>
                                                                </tr>
                                                            )
                                                        }
                                                        <tr className={classes.totalsContainer}>
                                                            <td className={classes.tableText}><b>{'Totals:'}</b></td>
                                                            <td className={classes.tableText}><b>{subheader.hours}</b></td>
                                                            <td className={classes.tableText}><b>{subheader.regular_hours}</b></td>
                                                            <td className={classes.tableText}><b>{`$ ${subheader.regular_hours_pay}`}</b></td>
                                                            <td className={classes.tableText}><b>{subheader.overtime_week}</b></td>
                                                            <td className={classes.tableText}><b>{`$ ${subheader.overtime_week_pay}`}</b></td>
                                                            <td className={classes.tableText}><b>{subheader.overtime_holiday}</b></td>
                                                            <td className={classes.tableText}><b>{`$ ${subheader.overtime_holiday_pay}`}</b></td>
                                                            <td className={classes.tableText}><b>{`$ ${subheader.total}`}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <div className={classes.tableContainer}>
                                <table className={classes.summaryTable} style={{ backgroundColor: '#6E6E6E', pageBreakAfter: 'always' }}>
                                    <thead>
                                        <tr className={classes.tableRow}>
                                            <th className={classes.summaryHeaderText}>{'Total hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Regular hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Regular hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'OT hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'OT hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'Holiday hours'}</th>
                                            <th className={classes.summaryHeaderText}>{'Holiday hours $'}</th>
                                            <th className={classes.summaryHeaderText}>{'Total paid'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {
                                                getTotalsNM(header.subheadersNM).map((item, i) => <td key={i.toString()} className={classes.summaryText}>{item}</td>)
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default EmployeePayToPrint;