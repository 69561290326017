import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';

import '../../assets/css/print.css';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    headerContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    logo: {
        maxHeight: 80
    },
    textContainer: {
        fontSize: 10,
        color: '#000'
    },
}));

const Text = ({ title, color }) => {
    const classes = useStyles();

    return <TableCell
        align='center'
        style={{ backgroundColor: color ? color : 'transparent' }}
        className={classes.textContainer}
    >
        {title}
    </TableCell>
}

const EmployeePaySummaryToPrint = ({ week, data }) => {

    const classes = useStyles();

    const renderPiecePay = header => {
        let piecePay = 0;
        header?.subheadersM?.map(item => piecePay += Number(item.base_pay));

        return `$ ${Number(piecePay).toFixed(2)}`;
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerContent}>
                    <span><b>{`Printing date: `}</b>{dayjs().format('MM/DD/YYYY HH:mm')}</span>
                    <span><b>{`Year-Week: `}</b>{week}</span>
                </div>
                <img className={classes.logo} src={Logo} alt='logo cfg' />
            </div>
            <TableContainer component={Box}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <Text title='Employee' />
                            <Text title='Regular hours' />
                            <Text title='OT hours' />
                            <Text title='OT + Regular' />
                            <Text title='Piece pay' />
                            <Text title='Bonus' />
                            <Text title='Regular rate' />
                            <Text title='Total' />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.map(item =>
                                <TableRow key={item.idemployee_pay_header}>
                                    <Text title={item.employee?.name} />
                                    <Text title={`$ ${item.regular_hours_pay}`} />
                                    <Text title={`$ ${item.paylocity_overtime_hours_pay}`} />
                                    <Text title={`$ ${(Number(item.paylocity_overtime_hours_pay) + Number(item.regular_hours_pay)).toFixed(2)}`} />
                                    <Text title={renderPiecePay(item)} />
                                    <Text title={`$ ${item.bonus}`} />
                                    <Text title={`$ ${(Number(item.overtime_base_rate) / 1.5).toFixed(2)}`} />
                                    <Text title={`$ ${item.paylocity_total}`} />
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default EmployeePaySummaryToPrint;